
import { defineComponent } from 'vue';
import {
  isPlatform,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  // IonButtons,
  // IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonItemGroup,
  IonItemDivider,
} from '@ionic/vue';
import {
  restaurantOutline,
  storefrontOutline,
  logOutOutline,
  closeOutline,
  peopleOutline,
  starOutline,
  logoInstagram,
  documentTextOutline,
  chevronForwardOutline,
  helpOutline,
} from 'ionicons/icons';
import store from '@/store';
import { logoutUser, deleteUser } from '@/utils/methods/userMethods';
import { openInAppBrowser } from '@/utils/methods/capacitorMethods';
import { tblVars } from '@/variables/environmentVariables';

export default defineComponent({
  name: 'Profil',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    // IonButtons,
    // IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonItemGroup,
    IonItemDivider,
  },
  data() {
    return {
      restaurantOutline,
      storefrontOutline,
      logOutOutline,
      closeOutline,
      peopleOutline,
      starOutline,
      logoInstagram,
      documentTextOutline,
      chevronForwardOutline,
      helpOutline,
      checkAndroid: isPlatform('android'),
      checkIos: isPlatform('ios'),
      tblVars,
    };
  },
  methods: {
    logoutUser,
    deleteUser,
    openInAppBrowser,
  },
  computed: {
    loginState: () => {
      return store.getters.getLoginState;
    },
    currentUserInfo() {
      return store.getters.getUserInfo;
    },
  },
});
